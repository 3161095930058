import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  H,
  Grid,
  Wrapper,
  BackgroundImageWrapper,
  Button,
  ArrowRightIcon,
} from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'
import BackgroundImage from '@farewill/ui/components/Image/BackgroundImageWrapper'

import { FF_FUNERAL_PLANS_TV_AD_ENABLED, NOTICE_BANNER_ENABLED } from 'config'
import AspectRatioWrapper from 'components/AspectRatioWrapper'
import PreventOrphan from 'components/PreventOrphan'
import { CORE_PRODUCTS, PRODUCTS, PRODUCTS_MAP } from 'lib/products/constants'
import { PRODUCT_LINKS_MAP } from 'lib/navigation/constants'

const StyledHero = styled(Wrapper)`
  text-align: center;
  color: ${COLOR.BLACK};
`

const StyledTitle = styled(H)`
  margin-bottom: ${GTR.L};

  ${screenMax.m`
    font-size: ${FONT.SIZE.XXL};
  `}

  ${screenMax.s`
    font-size: ${FONT.SIZE.XL};
  `}
`

const StyledProductLink = styled(Button.White)`
  height: 100%;
`

const StyledImageWrapper = styled.div`
  position: relative;
  margin: 0 auto -${GTR.S};
  max-width: 350px;

  ${screenMin.m`
    max-width: 500px;
    margin-bottom: -${GTR.L};
    ${FF_FUNERAL_PLANS_TV_AD_ENABLED ? `padding-top: ${GTR.M}` : ''};
  `}

  ${screenMin.l`
    margin-bottom: -${GTR.XL};
  `}
`

const StyledImage = styled(BackgroundImageWrapper)`
  z-index: 1;
  pointer-events: none;
`

const StyledTVBadge = styled(BackgroundImage)`
  position: absolute;

  width: 80px;
  height: 80px;
  padding: 0.5em;
  font-size: ${FONT.SIZE.S};
  line-height: 1.25;
  color: ${COLOR.BLACK};

  text-align: center;
  display: flex;
  align-items: center;

  ${FF_FUNERAL_PLANS_TV_AD_ENABLED
    ? `
    right: -3%;
    top: 5%;
    transform: rotate(5deg);
    padding-top: 5px;
  `
    : `
    right: 8%;
    top: 12%;
    transform: rotate(-15deg);
    font-weight: ${FONT.WEIGHT.BOLD};
  `}

  ${screenMin.m`
  width: 90px;
  height: 90px;
  ${
    FF_FUNERAL_PLANS_TV_AD_ENABLED
      ? `
    right: 4%;
    top: 8%;
  `
      : `
    right: 12%;
    top: 20%;
  `
  }
    font-size: ${FONT.SIZE.M};
  `}
`

const StyledGrid = styled(Grid)`
  /* styles for IE11 */
  @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    justify-content: space-between;
  }
`

const StyledGridItem = styled(Grid.Item)`
  /* styles for IE11 */
  @media screen and (max-width: 648px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 648px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    height: 60px;
    margin-bottom: 0;
    flex-basis: 32%;
    -ms-flex-preferred-size: 32%;
  }
`
const StyledSpan = styled.span`
  /* styles for IE11 */
  @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: 20px;
  }
`

const HERO_PRODUCTS = FF_FUNERAL_PLANS_TV_AD_ENABLED
  ? [PRODUCTS.FUNERAL_PLAN, ...CORE_PRODUCTS]
  : CORE_PRODUCTS

const Hero = (): ReactElement => (
  <>
    <StyledImageWrapper>
      <StyledImage
        tag={AspectRatioWrapper}
        ratio={16 / 9}
        imagePath={
          FF_FUNERAL_PLANS_TV_AD_ENABLED
            ? 'illustrations/family-at-the-table'
            : 'illustrations/tv-homepage-hero'
        }
        imageWidth={350}
        imageWidthFromM={500}
        cover
      />
      {!NOTICE_BANNER_ENABLED && (
        <StyledTVBadge
          imageWidth={80}
          imageWidthFromM={90}
          imagePath="textures/blob-white-1"
        >
          As seen on TV
        </StyledTVBadge>
      )}
    </StyledImageWrapper>

    <StyledHero container centered>
      <StyledTitle size="XL" decorative>
        <PreventOrphan>The simpler way to deal with death</PreventOrphan>
      </StyledTitle>

      <StyledGrid
        tag="ul"
        maxWidth={HERO_PRODUCTS.length > 3 ? 975 : 900}
        margin={[0, 'auto']}
        gap="S"
        gapFromM="M"
      >
        {HERO_PRODUCTS.map((id) => {
          const product = PRODUCTS_MAP[id]
          const productPath = PRODUCT_LINKS_MAP[id].path
          const ctaText = product.heroCtaText || product.ctaText
          return (
            <StyledGridItem
              tag="li"
              spanFromM={
                HERO_PRODUCTS.length > 3
                  ? 24 / HERO_PRODUCTS.length
                  : 12 / HERO_PRODUCTS.length
              }
              spanFromL={12 / HERO_PRODUCTS.length}
              key={product.title}
            >
              <StyledProductLink
                tag={Link}
                to={productPath}
                icon={ArrowRightIcon}
                stretch
                data-track-event="click"
                data-track-element="cta"
                data-track-slice="hero"
                data-track-product={id}
              >
                <StyledSpan>{ctaText}</StyledSpan>
              </StyledProductLink>
            </StyledGridItem>
          )
        })}
      </StyledGrid>
    </StyledHero>
  </>
)

export default Hero
