import React, { ReactElement } from 'react'
import { COLOR } from '@farewill/ui/tokens'
import { useRecordSplitPageview } from 'lib/hooks/useRecordSplitPageview'

import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'
import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import useContentfulLandingPageRedirect from './contentfulRedirectHook'
import Content from './Content'

const HomePage = ({
  pageContext,
}: {
  pageContext: {
    partnerSlugMap: string[]
  }
}): ReactElement => {
  useContentfulLandingPageRedirect(pageContext.partnerSlugMap)
  useRecordSplitPageview('homepage_page_view', undefined, { source: 'direct' })

  return (
    <>
      <JSONLD data={FAREWILL_JSONLD} />

      <DefaultLayout
        title="Wills And Probate Experts | The Simpler Cremation Service"
        description="Write a legal will in 15 minutes. Apply for probate or arrange a cremation over the phone."
        headerColor={COLOR.ACCENT.PRIMARY}
        headerShowLoginLink
      >
        <Content />
      </DefaultLayout>
    </>
  )
}

export default HomePage
