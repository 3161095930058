import React from 'react'

import { graphql, useStaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
import { Image, Wrapper, Button, ArrowRightIcon } from '@farewill/ui'
import { BREAKPOINT, COLOR } from '@farewill/ui/tokens'

import PATHS from 'paths'
import GuideCards from 'components/GuideCards'
import { normalizeArray } from 'lib/graphql/normalize'
import BreakpointSwitch from 'components/BreakpointSwitch'

const StyledButton = styled(Button.Plain)`
  color: ${COLOR.BLACK};
  text-decoration: underline;
`

const StyledSpan = styled.span`
  /* styles for IE11 */
  @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: 10px;
  }
`

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledInnerWrapper = styled(Wrapper)`
  position: relative;
`

const StyledTexture = styled(Wrapper)`
  position: absolute;

  img {
    width: 100%;
  }
`

const StyledTextureYellowDottedBlob = styled(StyledTexture)`
  left: 0;
  top: 60px;
`

const StyledTextureYellowRotatedDottedBlob = styled(StyledTexture)`
  right: -100px;
  bottom: -40px;
  transform: rotate(-180deg);
`

const StyledTextureYellowBlob = styled(StyledTexture)`
  left: 0;
  top: -80px;
`

const GuidesBanner = (): React.ReactElement => {
  const { guideData } = useStaticQuery(graphql`
    query {
      guideData: allContentfulGuide(
        sort: { fields: updatedAt, order: ASC }
        limit: 3
      ) {
        edges {
          node {
            ...GuideCardFields
          }
        }
      }
    }
  `)

  const guides: GatsbyTypes.ContentfulGuide[] = normalizeArray(guideData)

  return (
    <StyledWrapper background={COLOR.ACCENT.PRIMARY} padding={['M', 0, 0]}>
      <BreakpointSwitch
        breakAt={BREAKPOINT.M}
        aboveComponent={
          <>
            <StyledTextureYellowRotatedDottedBlob>
              <Image
                path="textures/blob-dots-yellow-half"
                width={270}
                loading="auto"
              />
            </StyledTextureYellowRotatedDottedBlob>
            <StyledTextureYellowBlob>
              <Image
                path="textures/BLOB_YELLOW03_1_r8pw6h"
                width={330}
                loading="auto"
              />
            </StyledTextureYellowBlob>
            <StyledTextureYellowDottedBlob>
              <Image
                path="textures/blob-dots-yellow-half"
                width={260}
                loading="auto"
              />
            </StyledTextureYellowDottedBlob>
          </>
        }
      />
      <StyledInnerWrapper
        container
        containerPaddingTop="XL"
        containerPaddingBottom="XL"
      >
        <GuideCards
          guides={guides}
          title="Guides"
          description="We’ve put all our expertise into these free guides to help you get
            to grips with everything to do with death."
        />
        <Wrapper centered separator>
          <StyledButton
            tag={Link}
            to={PATHS.GENERAL.CONTENT_HUB}
            icon={<ArrowRightIcon size="S" />}
            iconMargin="XS"
          >
            <StyledSpan>View all guides</StyledSpan>
          </StyledButton>
        </Wrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  )
}

export default GuidesBanner
