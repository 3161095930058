import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Image, H, P, Divider, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'
import TelephoneNumber from 'components/TelephoneNumber'
import { formattedOpenHours, formattedOpenDays } from 'lib/time/formatting'
import { PRODUCTS, SPECIALISTS } from 'lib/products/constants'
import Specialists from 'components/Specialists'
import useProductSupportStatus from 'lib/time/useProductSupportStatus'
import PhoneLineOpenIndicator from 'components/PhoneLineOpenIndicator'

const OpenMessageWrapper = styled(Wrapper)`
  display: inline-block;
  width: auto;

  ${screenMin.m`
    min-width: 108px;
  `}
`

const StyledSupport = styled.div`
  display: flex;
  flex-direction: column;

  ${screenMin.m`
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  `}
`

const StyledIllustration = styled(Image)`
  max-width: 300px;
  display: block;
  margin: 0 auto;

  ${screenMin.m`
    margin: -15% auto;
  `}
`

const StyledTextWrapper = styled.div`
  ${screenMin.m`
    padding-right: ${GTR.L};
    flex: 1;
  `}
`

const StyledContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${screenMin.l`
    flex-direction: row;
    align-items: center;
  `}
`

const StyledDivider = styled(Divider)`
  height: unset;
  align-self: stretch;
  margin: ${GTR.XS} ${GTR.S};

  ${screenMin.l`
    margin-top: 0;
    margin-bottom: 0;
  `}
`

const StyledTelephoneNumber = styled(TelephoneNumber)`
  font-size: ${FONT.SIZE.L};
`

const Support = (): ReactElement => {
  const isProductSupportOpen = useProductSupportStatus(PRODUCTS.FUNERAL)
  return (
    <StyledSupport>
      <StyledIllustration
        path="illustrations/phone-with-hand-and-cat"
        width={300}
        stretch
      />

      <StyledTextWrapper>
        <H size="L" decorative>
          <PreventOrphan>We’re only a phone call away</PreventOrphan>
        </H>

        <P>
          Any questions? Our friendly specialists are here to help from{' '}
          {formattedOpenHours(PRODUCTS.WILLS)},{' '}
          {formattedOpenDays(PRODUCTS.WILLS)}.
        </P>

        <Wrapper separator />

        <StyledContactWrapper>
          <Specialists group={SPECIALISTS.CROSS_TEAM} extraSmall />
          <StyledDivider vertical />
          <Wrapper>
            <StyledTelephoneNumber />
            {isProductSupportOpen && (
              <Wrapper margin={['XS', 0, 0, 'XXS']}>
                <OpenMessageWrapper>
                  <PhoneLineOpenIndicator text="Line open - give us a call" />
                </OpenMessageWrapper>
              </Wrapper>
            )}
          </Wrapper>
        </StyledContactWrapper>
      </StyledTextWrapper>
    </StyledSupport>
  )
}

export default Support
