import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import Charity from 'components/Charity'
import GuidesBanner from 'components/GuidesBanner'
import ProductCards from 'components/ProductCards'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'
import WrapperWithEdge from 'components/WrapperWithEdge'

import Hero from './Hero'
import Support from './Support'
import Team from './Team'

const StyledWhiteWrapper = styled(Wrapper)`
  position: relative;
  z-index: 1;
`

const Content = (): ReactElement => (
  <>
    <WrapperWithEdge direction="down" edgeBackgroundColor={COLOR.WHITE}>
      <Hero />
    </WrapperWithEdge>

    <Wrapper background={COLOR.WHITE} padding={[0, 0, 'L']}>
      <Wrapper container>
        <Team />
      </Wrapper>
      <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
        <TrustpilotReviews />
      </Wrapper>
    </Wrapper>

    <Divider />

    <Wrapper container>
      <ProductCards />
    </Wrapper>

    <Divider />

    <StyledWhiteWrapper background={COLOR.WHITE}>
      <Wrapper container>
        <Support />
      </Wrapper>

      <Divider container />

      <Charity />
    </StyledWhiteWrapper>

    <GuidesBanner />
  </>
)

export default Content
