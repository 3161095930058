import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button, Image, H, P, Wrapper, ArrowRightIcon } from '@farewill/ui'
import { COLOR, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'
import { screen } from '@farewill/ui/helpers/responsive'

const StyledProductCard = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.WHITE};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: solid 1px ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER_RADIUS.S};

  ${screen.m`
    flex-direction: row-reverse;
    align-items: center;

    & > * {
      flex: 1;
    }
  `}
`

const StyledImageWrapper = styled(Wrapper)`
  position: relative;
  max-width: 300px;
  left: 50%;
  transform: translateX(-50%);

  ${screen.m`
    left: 0;
    transform: translateX(0);
  `}

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`

const StyledIllustration = styled(Image)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  max-width: 300px;
  max-height: 250px;
  object-fit: contain;
`

const StyledContentWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  ${screen.m`
    padding-right: ${GTR.L};
  `}
`

type ProductCardProps = {
  className?: string
  title: string
  path: string
  illustration?: string
  description?: string
  ctaText?: string
}

const ProductCard = ({
  className,
  title,
  path,
  illustration,
  description,
  ctaText,
}: ProductCardProps): ReactElement => (
  <StyledProductCard
    className={className}
    container
    paddingFromM={['XS', 'L']}
    paddingFromL="M"
  >
    <StyledImageWrapper>
      <StyledIllustration
        path={`e_trim/illustrations/${illustration}`}
        width={600}
        height={500}
      />
    </StyledImageWrapper>
    <StyledContentWrapper>
      <Wrapper margin={[0, 0, 'M', 0]}>
        <H size="M">{title}</H>
        <P>{description}</P>
      </Wrapper>
      <Button.Primary
        tag={Link}
        to={path}
        icon={<ArrowRightIcon size="M" />}
        separateIcon
        stretch
      >
        {ctaText}
      </Button.Primary>
    </StyledContentWrapper>
  </StyledProductCard>
)

export default ProductCard
