import { useEffect } from 'react'
import { navigate } from 'gatsby'

import { getQueryParameters } from 'lib/url/getQueryParameters'

const useContentfulLandingPageRedirect = (slugMap) => {
  useEffect(() => {
    const { utm_campaign: utmCampaign } = getQueryParameters()
    const searchKey = utmCampaign
    const formattedSearchKey = searchKey && String(searchKey).toLowerCase()

    if (searchKey && slugMap[formattedSearchKey]) {
      navigate(slugMap[formattedSearchKey], { replace: true })
    }
  }, [])
}

export default useContentfulLandingPageRedirect
